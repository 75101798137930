<template>
  <div>
    <b-card
      no-body
    >
      <b-card-body
        :title="$t('DodajObrazacUgovora')"
      >
        <h4
          v-if="porukaIzmena"
          class="text-danger"
        >
          {{ $t('Promenom obrasca menja se štampa svih ugovora!') }}
        </h4>
        <b-row>
          <b-col
            md="6"
            xl="6"
            class="mb-1"
          >

            <!-- basic -->
            <b-form-group
              :label="$t('Naziv')"
              label-for="naziv"
            >
              <b-form-input
                id="naziv"
                v-model="data.naziv"
                :placeholder="$t('Naziv')"
              />
              <small
                v-if="formErrors['naziv']"
                class="text-danger"
              >
                {{ $t(formErrors['naziv'][0]) }}
              </small>
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            xl="6"
            class="mb-1"
          >

            <!-- basic -->
            <b-form-group
              :label="$t('Opis')"
              label-for="opis"
            >
              <b-form-input
                id="opis"
                v-model="data.opis"
                :placeholder="$t('Opis')"
              />
              <small
                v-if="formErrors['opis']"
                class="text-danger"
              >
                {{ $t(formErrors['opis'][0]) }}
              </small>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            md="12"
            xl="12"
          >
            <b-form-group
              :label="$t('Tekst')"
              label-for="tekst"
            >
              <quill-editor
                id="tekst"
                ref="editorObrasca"
                v-model="data.tekst"
                :options="editorOption"
                style="size: 7in 9.25in; margin: 27mm 16mm 27mm 16mm;"
                @focus="pozicijaKursora($event)"
                @change="pozicijaKursora($event)"
              >
                <div
                  id="toolbar"
                  slot="toolbar"
                >
                  <!-- Add a bold button -->
                  <button class="ql-bold">
                    Bold
                  </button>
                  <button class="ql-italic">
                    Italic
                  </button>
                  <!-- Add font size dropdown -->
                  <select class="ql-align">
                    <option value="right" />
                    <option selected />
                    <option value="center" />
                    <option value="justify" />
                  </select>
                  <!-- Add font size dropdown -->
                  <select class="ql-size">
                    <option value="small" />
                    <!-- Note a missing, thus falsy value, is used to reset to default -->
                    <option selected />
                    <option value="large" />
                    <option value="huge" />
                  </select>
                  <select
                    class="myDropdwon"
                    style="width:auto"
                    @change="prilagodjenTekst($event)"
                  >
                    <option
                      value=""
                    >
                      {{ $t('PrilagodjenoPolje') }}
                    </option>
                    <option
                      v-for="(choice, key) in prilagodjenaPolja"
                      :key="key"
                      :value="choice.value"
                    >
                      {{ $t(choice.text) }}
                    </option>
                  </select>
                  <span class="ql-formats">
                    <button
                      class="ql-list"
                      value="ordered"
                    />
                    <button
                      class="ql-list"
                      value="bullet"
                    />
                    <button
                      class="ql-indent"
                      value="-1"
                    />
                    <button
                      class="ql-indent"
                      value="+1"
                    />
                  </span>
                </div>
              </quill-editor>
              <small
                v-if="formErrors['tekst']"
                class="text-danger"
              >
                {{ $t(formErrors['tekst'][0]) }}
              </small>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
      <b-card-body>
        <b-row>
          <b-col
            md="4"
            xl="4"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              size="sm"
              @click="snimiObrazac"
            >
              {{ $t("Snimi") }}
            </b-button>
            <router-link :to="{ name: 'autoskola-kandidati' }">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="secondary"
                size="sm"
              >
                {{ $t("Odustani") }}
              </b-button>
            </router-link>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BCard, BCardBody, BFormInput, BButton,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'

export default {
  components: {
    BFormGroup,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BFormInput,
    BButton,
    quillEditor,
  },
  directives: {
    Ripple,
  },
  data: () => ({
    porukaIzmena: false,
    pozicija: 0,
    data: {},
    editorOption: {
      modules: {
        toolbar: '#toolbar',
      },
    },
    formErrors: [],
    prilagodjenaPolja: [
      { value: '%ImeSkole%', text: 'ImeSkole' },
      { value: '%NazivPravnogLica%', text: 'NazivPravnogLica' },
      { value: '%SedistePravnogLica%', text: 'SedistePravnogLica' },
      { value: '%MestoAutoSkole%', text: 'MestoAutoSkole' },
      { value: '%ZastupnikSkole%', text: 'ZastupnikSkole' },
      { value: '%DatumUpisaRegistar%', text: 'DatumUpisaURegistar' },
      { value: '%ImeKandidata%', text: 'ImeKandidata' },
      { value: '%PrezimeKandidata%', text: 'PrezimeKandidata' },
      { value: '%IDKandidata%', text: 'IDKandidata' },
      { value: '%JMBGKandidata%', text: 'JMBGKandidata' },
      { value: '%AdresaKandidata%', text: 'AdresaKandidata' },
      { value: '%MestoKandidata%', text: 'MestoKandidata' },
      { value: '%TelefonKandidata%', text: 'TelefonKandidata' },
      { value: '%EmailKandidata%', text: 'EmailKandidata' },
      { value: '%ImePrezimeRoditeljaKandidata%', text: 'ImePrezimeRoditeljaKandidata' },
      { value: '%BrLKRoditeljaKandidata%', text: 'BrLKRoditeljaKandidata' },
      { value: '%Cene%', text: 'Cene' },
      { value: '%NacinPlacanja%', text: 'NacinPlacanja' },
      { value: '%RokRealizacije%', text: 'RokRealizacije' },
      { value: '%KategorijaKandidata%', text: 'KategorijaKandidata' },
      { value: '%Rate%', text: 'Rate' },
    ],
  }),
  beforeCreate() {
    this.$http.get('/autoskole-administratori/obrasci-prilagodjena-polja').then(response => {
      Object.entries(response.data).forEach(value => {
        this.prilagodjenaPolja.push({ value: value[0], text: `${value[1]}` })
      })
    })
  },
  mounted() {
    this.pokupiListu()
  },
  methods: {
    pozicijaKursora() {
      const editor = this.$refs.editorObrasca.quill
      if (editor.getSelection() !== null) {
        this.pozicija = editor.getSelection().index
      }
    },
    pokupiListu() {
      if (typeof this.$route.params.id !== 'undefined') {
        this.porukaIzmena = true
        this.$http.get(`/autoskole-administratori/obrasci-ugovora/${this.$route.params.id}`).then(response => {
          this.data = response.data
        })
      }
    },
    prilagodjenTekst(tekst) {
      const editor = this.$refs.editorObrasca.quill
      editor.insertText(this.pozicija, tekst.target.value)
      this.pozicija += tekst.target.value.length
    },
    snimiObrazac() {
      if (typeof this.$route.params.id !== 'undefined' && this.$route.params.id !== 'kreiraj') {
        this.$http.put(`/autoskole-administratori/obrasci-ugovora/${this.$route.params.id}`, this.data).then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$i18n.t('ObrazacJeSnimljen'),
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.$router.push({ name: 'autoskola-obrasci-ugovora' })
        })
          .catch(error => {
            if (error.response.status === 422) {
              this.formErrors = error.response.data.errors
            }
          })
      } else {
        this.$http.post('/autoskole-administratori/obrasci-ugovora', this.data).then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$i18n.t('ObrazacJeSnimljen'),
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.$router.push({ name: 'autoskola-obrasci-ugovora', params: { id: this.$route.params.id } })
        })
          .catch(error => {
            if (error.response.status === 422) {
              this.formErrors = error.response.data.errors
            }
          })
      }
    },
  },
}
</script>

<style lang="scss">
  .myDropdwon {
    min-width: 180px;
  }
  .myDropdwon .ql-picker-item:before {
    content: attr(data-label);
  }
  .myDropdwon .ql-picker-label:before {
    content: attr(data-label);
  }
</style>
